import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { CheckCircleIcon } from "@heroicons/react/outline"
import Layout from "../components/layout"
import Socail from "../components/social"

export default function Thanks() {
  return (
    <Layout>
      <div className="md:pt-16 pt-6 flex md:space-x-16 px-4 lg:px-0">
        <div className="space-y-5">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="lg:text-4xl text-xl font-bold">
                Спасибо, что обратились!
              </h1>
              <div className="py-6 flex space-x-4 items-center">
                <CheckCircleIcon className="h-10 w-10 text-green-500" />
                <div className="font-semibold font-serif italic">
                  Ваша завка отправлена
                </div>
              </div>
              <div className="lg:block hidden">
                <div className="lg:text-xl font-serif italic">
                  В ближайшее время с вами свяжется наш специалист и
                  проконсультирует вас по всем вопросам!
                  <p className="py-4 text-base text-gray-500">
                    С уважением, команда <br />
                    &laquo;Красильников, Фролов и партнеры&raquo;
                  </p>
                </div>
                <div className="flex space-x-3 py-4 items-center">
                  Подпишитесь на нас в социальных сетях
                </div>
                <Socail />
              </div>
            </div>
            <div className="lg:w-1/3">
              <StaticImage
                src="../images/thx.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Красильников, Фролов и партнеры"
              />
            </div>
          </div>
          <div className="block lg:hidden py-4">
            <div className="lg:text-xl font-serif italic">
              В ближайшее время с вами свяжется наш специалист и
              проконсультирует вас по всем вопросам!
              <p className="py-4 text-base text-gray-500">
                С уважением, команда <br />
                &laquo;Красильников, Фролов и партнеры&raquo;
              </p>
            </div>
            <div className="flex space-x-3 items-center py-4">
              Подпишитесь на нас в социальных сетях
            </div>
            <Socail />
          </div>
        </div>
      </div>
    </Layout>
  )
}
